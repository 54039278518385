/* global chatbotOptions */

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import BotMessage from "./components/BotMessage";
import UserMessage from "./components/UserMessage";
import Messages from "./components/Messages";
import Input from "./components/Input";
import Header from "./components/Header";

import API from "./ChatbotAPI";

import "./styles.css";

function Chatbot() {
  const [messages, setMessages] = useState([]);

  const backgroundColor = chatbotOptions.bgcolor;
  const textColor = chatbotOptions.textColor;
  const chatbotWidth = chatbotOptions.chatbotWidth;

  useEffect(() => {
    async function loadWelcomeMessage() {
      setMessages([
        <BotMessage
          key="0"
          fetchMessage={async () => await API.GetChatbotResponse("hi")}
        />,
      ]);
    }
    loadWelcomeMessage();
  }, []);

  const send = async (text) => {
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={async () => await API.GetChatbotResponse(text)}
      />
    );
    setMessages(newMessages);
  };

  // Apply the styles dynamically
  const chatbotStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: chatbotWidth
  };

  return (
    <div className="chatbot" style={chatbotStyle}>
      <Header />
      <Messages messages={messages} />
      <Input onSend={send} />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Chatbot />, rootElement);
