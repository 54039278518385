/* global chatbotOptions */

import axios from "axios";
axios.defaults.withCredentials = true;

const API = {
  GetChatbotResponse: async (message) => {
    let env = process.env.REACT_APP_CURRENT_ENV || "prod";
    if(chatbotOptions.sandbox){
      env = process.env.REACT_APP_CURRENT_ENV || "dev";
    } else {
    }
    const url =
      // "https://vivlthyjxf.execute-api.eu-west-2.amazonaws.com/sandbox"; // Replace with your API Gateway URL
      // "https://vivlthyjxf.execute-api.eu-west-2.amazonaws.com/dev/dev";
      // "https://vivlthyjxf.execute-api.eu-west-2.amazonaws.com/dev";
      // "https://9dulhpjiuj.execute-api.eu-west-2.amazonaws.com/prod/prod";
      `https://botapi.unherd.com`;
    try {
      const response = await axios.post(
        url,
        { query_text: message },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.result; // Adjust based on the structure of your Lambda response
    } catch (error) {
      console.error("Error:", error);
      return "Sorry, I'm having trouble understanding you right now. Please try again later. 🤖";
    }
  },
};
export default API;
